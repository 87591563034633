import clsx from "clsx";
import React, { FC } from "react";

import AsSeenInSquiggle from "./AsSeenInSquiggle.png";
import GoodFoodLogo from "./GoodFoodLogo.png";
import HerCanberraLogo from "./HerCanberraLogo.png";
import TheCanberraTimesLogo from "./TheCanberraTimesLogo.png";

const Block: FC<{ image }> = ({ image }) => {
  return (
    <div className={clsx("w-[75%] sm:w-1/3")}>
      <img src={image.src} className="w-full" />
    </div>
  );
};

const AsSeenIn: FC = () => {
  return (
    <div className={clsx("mt-12 py-12", "sm:w-[1061px] sm:m-auto")}>
      <div className={clsx("flex justify-center items-center", "flex-col", "relative")}>
        <hr className={clsx("z-0", "w-full", "border-charcoal border-dashed", "absolute top-[18px]")} />
        <h2
          className={clsx(
            "z-10",
            "text-center text-[26px] leading-[32px] font-sharp-grotesk-medium",
            "bg-vood-light",
            "px-[45px] pb-[6px]",
          )}
        >
          As Seen In
        </h2>
        <img src={AsSeenInSquiggle.src} />
      </div>

      <div
        className={clsx(
          "flex justify-between items-center",
          "gap-[40px] sm:gap-[152px]",
          "flex-col sm:flex-row",
          "pt-[96px]",
        )}
      >
        <Block image={HerCanberraLogo} />
        <Block image={TheCanberraTimesLogo} />
        <Block image={GoodFoodLogo} />
      </div>

      <hr className={clsx("hidden sm:block m-auto", "w-full mt-[112px]", "border-charcoal border-dashed")} />
    </div>
  );
};

export default AsSeenIn;
