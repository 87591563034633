import clsx from "clsx";
import React, { FC } from "react";

import GoodMoodFood1 from "./GoodMoodFood1.jpg";
import GoodMoodFood2 from "./GoodMoodFood2.jpg";
import GoodMoodFood3 from "./GoodMoodFood3.jpg";
import Spot1 from "./Spot1";
import Spot2 from "./Spot2";
import Spot3 from "./Spot3";
import Spot4 from "./Spot4";

const GoodMoodFood: FC = () => {
  return (
    <div className={clsx("flex items-center justify-center")}>
      <div
        className={clsx(
          "py-12",
          "relative",
          "h-[480px] sm:h-[1450px]",
          "flex items-center justify-center",
          "w-[1280px]",
        )}
      >
        <Spot1
          className={clsx("absolute top-[30px] sm:top-[366px] left-[220px] sm:left-[679px] w-[18px] sm:w-[44px]")}
        />
        <Spot2 className={clsx("absolute top-[290px] sm:top-[820px] left-[8px] sm:left-[33px] w-[18px] sm:w-[44px]")} />
        <Spot3
          className={clsx("absolute top-[293px] sm:top-[600px] left-[120px] sm:left-[542px] w-[18px] sm:w-[44px]")}
        />
        <Spot4
          className={clsx("absolute top-[420px] sm:top-[1200px] left-[247px] sm:left-[923px] w-[18px] sm:w-[44px]")}
        />

        <img
          src={GoodMoodFood1.src}
          className={clsx("w-48 sm:w-[518px]", "absolute", "left-2 top-[0px]", "rounded-3xl")}
        />
        <img
          src={GoodMoodFood2.src}
          className={clsx("w-48 sm:w-[518px]", "absolute", "right-2 top-24 sm:top-[285px]", "rounded-3xl")}
        />
        <img
          src={GoodMoodFood3.src}
          className={clsx(
            "w-48 sm:w-[650px]",
            "absolute",
            "left-2 sm:left-[221px] top-[330px] sm:top-[848px]",
            "rounded-3xl",
          )}
        />

        <h1
          className={clsx(
            "text-[36px] sm:text-[120px] leading-[40px] sm:leading-[120px] text-center",
            "z-10",
            "font-sharp-grotesk-medium",
          )}
        >
          Good mood food
        </h1>
      </div>
    </div>
  );
};

export default GoodMoodFood;
