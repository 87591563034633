import React, { FC } from "react";

const Spot1: FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      width="44"
      height="41"
      viewBox="0 0 44 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4692_25889)">
        <path
          d="M15.053 13.5795C16.2725 14.6747 18.9313 13.7189 18.2516 11.2298C15.193 0.417265 1.59915 1.23368 15.053 13.5795Z"
          fill="#FCC766"
        />
        <path
          d="M22.1504 11.6721C22.3503 13.3448 24.1095 13.982 25.269 13.1656C25.9287 12.7076 26.1686 11.7717 25.9887 12.2496C33.8051 -8.43957 20.871 1.07865 22.1504 11.6721ZM26.1486 11.7518C26.1486 11.7518 26.1286 11.8514 26.1086 11.8912C26.1086 11.8514 26.1286 11.7916 26.1486 11.7518Z"
          fill="#FCC766"
        />
        <path
          d="M28.4274 16.0089C31.346 18.5776 44.62 4.9375 37.5432 4.9375C36.0239 4.9375 24.849 12.9623 28.4274 16.0089Z"
          fill="#FCC766"
        />
        <path
          d="M2.33834 15.3128C0.319257 15.3128 -0.940171 18.1603 0.83902 19.7135C6.13661 24.3531 30.2057 15.2531 2.33834 15.3128Z"
          fill="#FCC766"
        />
        <path
          d="M35.7036 25.1709C47.4983 27.4608 43.8199 21.7459 41.9808 21.3477L30.0062 18.8984C28.6269 18.8984 22.8695 22.8013 35.7236 25.151L35.7036 25.1709Z"
          fill="#FCC766"
        />
        <path
          d="M30.2067 36.859C30.4266 37.1378 36.7837 40.8415 27.6279 26.0066C25.9286 23.1989 20.611 25.0309 30.2067 36.859Z"
          fill="#FCC766"
        />
        <path
          d="M20.2302 34.2499C21.3297 29.8093 23.3088 24.6918 19.5106 25.11C13.4733 25.787 15.5524 53.3659 20.2302 34.2499Z"
          fill="#FCC766"
        />
        <path
          d="M5.93785 31.4212C7.01736 31.6203 21.7307 23.2969 14.7738 23.2969C14.494 23.2969 0.880146 30.3858 5.93785 31.4212Z"
          fill="#FCC766"
        />
      </g>
      <defs>
        <clipPath id="clip0_4692_25889">
          <rect width="44" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Spot1;
