import React, { FC } from "react";

const Spot4: FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      width="42"
      height="45"
      viewBox="0 0 42 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4692_25912)">
        <path
          d="M10.3977 17.3489C18.2169 20.8807 12.7333 4.9064 8.08242 5.35295C1.90834 5.96188 9.05728 16.7197 10.3977 17.3489Z"
          fill="#FCC766"
        />
        <path
          d="M19.1108 12.2647C20.1059 24.4839 31.8651 -0.908489 22.3806 0.0252047C19.9231 0.268777 18.339 1.7911 19.1108 12.2647Z"
          fill="#FCC766"
        />
        <path
          d="M33.2464 44.1659C35.2165 46.5002 39.6236 43.4961 37.0443 40.3906C34.6884 37.5895 25.1023 24.7816 25.082 32.7789C25.082 34.078 25.8538 35.2552 33.2261 44.1659H33.2464Z"
          fill="#FCC766"
        />
        <path
          d="M28.3926 24.9848C28.3926 28.0904 31.8452 27.5017 35.5618 27.2987C43.8887 26.9334 44.3964 22.4273 35.5618 22.4273C31.9061 22.4273 28.3926 22.001 28.3926 24.9848Z"
          fill="#FCC766"
        />
        <path
          d="M35.1147 14.0635C36.6988 12.5818 34.9928 10.4099 33.2259 11.5263C21.223 18.7726 27.5596 21.2489 35.1147 14.0635Z"
          fill="#FCC766"
        />
        <path
          d="M13.9517 40.4564C13.6674 41.5524 13.8705 42.6891 14.8656 43.3792C24.5532 50.2196 20.8366 15.0234 13.9517 40.4564Z"
          fill="#FCC766"
        />
        <path
          d="M0.0409871 22.6949C-0.83232 27.7896 15.8621 27.7896 11.7392 21.9845C10.7847 20.6651 0.670581 18.7571 0.0409871 22.6949Z"
          fill="#FCC766"
        />
        <path
          d="M2.84429 35.2383C0.7321 37.0854 3.57543 40.536 5.89071 38.5063C12.1866 32.9244 14.8066 31.2194 12.9584 29.2302C11.1712 27.3425 9.18084 29.6159 2.84429 35.2383Z"
          fill="#FCC766"
        />
      </g>
      <defs>
        <clipPath id="clip0_4692_25912">
          <rect width="42" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Spot4;
