import React, { FC } from "react";

const Spot3: FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4692_25903)">
        <path
          d="M31.9492 11.6809C31.9492 16.8388 39.7419 16.8388 39.7419 11.6809C39.7419 6.52303 31.9492 6.52303 31.9492 11.6809Z"
          fill="#FCC766"
        />
        <path
          d="M34.207 24.1809C34.207 29.3388 41.9997 29.3388 41.9997 24.1809C41.9997 19.023 34.207 19.023 34.207 24.1809Z"
          fill="#FCC766"
        />
        <path
          d="M32.8109 38.4085C37.9433 38.4085 37.9702 30.5781 32.8109 30.5781C27.6516 30.5781 27.6516 38.4085 32.8109 38.4085Z"
          fill="#FCC766"
        />
        <path
          d="M20.0453 43.0022C25.1777 43.0022 25.2046 35.1719 20.0453 35.1719C14.886 35.1719 14.886 43.0022 20.0453 43.0022Z"
          fill="#FCC766"
        />
        <path
          d="M4.43359 33.1653C4.43359 38.3231 12.2263 38.3231 12.2263 33.1653C12.2263 28.0074 4.43359 28.0074 4.43359 33.1653Z"
          fill="#FCC766"
        />
        <path
          d="M0 21.0268C0 23.1648 1.77351 24.9553 3.89635 24.9553C9.02879 24.9553 9.05566 17.125 3.89635 17.125C1.77351 17.125 0 18.9156 0 21.0535V21.0268Z"
          fill="#FCC766"
        />
        <path
          d="M6.36914 8.47212C6.36914 10.6101 8.14265 12.4006 10.2655 12.4006C15.3979 12.4006 15.4248 4.57031 10.2655 4.57031C8.14265 4.57031 6.36914 6.36087 6.36914 8.49884V8.47212Z"
          fill="#FCC766"
        />
        <path
          d="M19.5078 3.87621C19.5078 9.03407 27.3005 9.03407 27.3005 3.87621C27.3005 -1.28165 19.5078 -1.28165 19.5078 3.87621Z"
          fill="#FCC766"
        />
      </g>
      <defs>
        <clipPath id="clip0_4692_25903">
          <rect width="42" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Spot3;
