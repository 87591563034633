import clsx from "clsx";
import React, { FC, ReactNode, useState } from "react";
import ArrowLeftIcon from "src/images/ArrowLeftIcon";
import ArrowRightIcon from "src/images/ArrowRightIcon";
import Link from "src/layout/Link";

import HeaderBackground1 from "./HeaderBackground1.jpg";
import HeaderBackground2 from "./HeaderBackground2.jpg";

interface HeaderItem {
  title: ReactNode;
  text: ReactNode;
  backgroundImage: any;
  backgroundColor: string;
}

const listItems: HeaderItem[] = [
  {
    title: (
      <>
        Plant-based
        <br className={clsx("sm:hidden")} /> with
        <br className={clsx("hidden sm:inline")} /> taste,
        <br className={clsx("sm:hidden")} /> delivered
      </>
    ),
    text: (
      <>
        Chef-designed, crafted by you,
        <br />
        ready in minutes
      </>
    ),
    backgroundImage: HeaderBackground2,
    backgroundColor: "#FCC766",
  },
  {
    title: (
      <>
        No plan.
        <br /> No minimum
        <br className={clsx("sm:hidden")} /> order.
      </>
    ),
    text: (
      <>
        Just good mood food.
        <br />
        Save on subscription.
      </>
    ),
    backgroundImage: HeaderBackground1,
    backgroundColor: "#F2C0C5",
  },
];

const Header: FC = () => {
  const [index, setIndex] = useState(0);

  const handlePrev = () => setIndex((index + listItems.length - 1) % listItems.length);

  const handleNext = () => setIndex((index + 1) % listItems.length);

  const item = listItems[index];

  return (
    <header
      className={clsx(
        "w-full h-[683px] sm:h-[778px]",
        "mb-0 sm:mb-[100px]",
        "bg-cover bg-center bg-no-repeat sm:bg-top",
        "relative",
        // `bg-[url('${HeaderBackground.src}')]`
        "p-2 sm:p-0",
      )}
      style={{
        backgroundImage: `url('${item.backgroundImage.src}')`,
      }}
    >
      <div
        className={clsx(
          "top-[164px] sm:top-auto bottom-auto sm:-bottom-24",
          "absolute mx-4 sm:m-auto left-0 right-0",
          "h-[414px] sm:h-[504px] sm:w-[844px]",
          "max-w-md sm:max-w-full",
          // `bg-[${item.backgroundColor}]`,
          "rounded-[30px] sm:rounded-[50px]",
          "flex items-center justify-center",
        )}
        style={{
          backgroundColor: item.backgroundColor,
        }}
      >
        <div className={clsx("flex items-center justify-start", "gap-2 sm:gap-6 flex-col")}>
          <h2
            className={clsx(
              "text-center text-[36px] sm:text-[50px] leading-[40px] sm:leading-[56px]",
              "font-sharp-grotesk-medium",
            )}
          >
            {item.title}
          </h2>
          <div
            className={clsx(
              "text-center text-[18px] sm:text-[26px] leading-[22px] sm:leading-[34px]",
              "mt-[20px] mb-[20px]",
              "font-sharp-grotesk-medium",
            )}
          >
            {item.text}
          </div>
          <div className={clsx("flex items-center justify-start", "gap-[10px] sm:gap-[20px] flex-col sm:flex-row")}>
            <Link href="/start">
              <div className={clsx("btn btn-sized btn-red")}>Get started</div>
            </Link>
            <Link href="/menu">
              <div className={clsx("btn btn-sized btn-white")}>View our menu</div>
            </Link>
          </div>

          <button
            onClick={handlePrev}
            className={clsx(
              "absolute left-[30%] sm:left-[-22px] bottom-[-22px] sm:bottom-[50%]",
              "rounded-full",
              "bg-white hover:bg-black",
              "text-black hover:text-white",
              "font-sharp-grotesk-medium",
              "flex items-center justify-center",
              "w-[44px] h-[44px]",
              "transition-colors duration-200",
            )}
          >
            <ArrowLeftIcon />
          </button>
          <button
            onClick={handleNext}
            className={clsx(
              "absolute right-[30%] sm:right-[-22px] bottom-[-22px] sm:bottom-[50%]",
              "rounded-full",
              "bg-white hover:bg-black",
              "text-black hover:text-white",
              "font-sharp-grotesk-medium",
              "w-[44px] h-[44px]",
              "flex items-center justify-center",
              "transition-colors duration-200",
            )}
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
