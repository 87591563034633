import React, { FC } from "react";
import BasicLayout from "src/layout/BasicLayout";
import Container from "src/layout/Container";
import Footer from "src/layout/footer-white/Footer";
import Main from "src/layout/Main";
import HeaderNav from "src/modules/navbar/HeaderNav";

import Header from "./0-header/Header";
import ImpossiblyDelicious from "./1-impossiblydelicious/ImpossiblyDelicious";
import HowThisWorks from "./2-howitworks/HowThisWorks";
import ViewOurMenu from "./3-viewourmenu/ViewOurMenu";
import GoodMoodFood from "./4-goodmoodfood/GoodMoodFood";
import OnTheMenu from "./5-onthemenu/OnTheMenu";
import VoodiesAreSaying from "./6-voodiesaresaying/VoodiesAreSaying";
import AsSeenIn from "./7-asseenin/AsSeenIn";
import ChefDesigned from "./9-chefdesigned/ChefDesigned";

const Home2Page: FC = () => {
  return (
    <BasicLayout title="Plant-based meals with taste, delivered">
      <Header />
      <Main>
        <Container>
          <ImpossiblyDelicious />
          <HowThisWorks />
          <ViewOurMenu />
          <GoodMoodFood />
          <OnTheMenu />
          <VoodiesAreSaying />
          <AsSeenIn />
        </Container>
      </Main>
      <ChefDesigned />
      <Footer />
      <HeaderNav />
    </BasicLayout>
  );
};

export default Home2Page;
