import clsx from "clsx";
import React, { FC, useEffect } from "react";

const ProductReviewPlugin: FC = () => {
  useEffect(() => {
    // console.log("productReviewCall");
    window.__productReviewCallbackQueue = window.__productReviewCallbackQueue || [];
    window.__productReviewCallbackQueue.push(function (ProductReview) {
      ProductReview!.use("reviews-horizontal", {
        identificationDetails: {
          type: "single",
          strategy: "from-internal-entry-id",
          identifier: "28a350a8-10eb-42a9-89ef-29da0a7eec6b",
        },
        container: "#pr-reviews-horizontal-widget",
      });
    });
  }, []);

  return (
    <div className={clsx("mt-12", "flex justify-center items-center")}>
      <div id="pr-reviews-horizontal-widget"></div>
    </div>
  );
};

export default ProductReviewPlugin;
