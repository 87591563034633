import clsx from "clsx";
import React, { FC } from "react";
import YouTube from "react-youtube";

import ProductReviewPlugin from "./ProductReviewPlugin";
import VoodiesAreSayingSquiggle from "./VoodiesAreSayingSquiggle.png";

export const VIDEO_ID = "IIFnW8rvuhk";

const VoodiesAreSaying: FC = () => {
  return (
    <div>
      <div className="py-12">
        <div className={clsx("flex flex-col justify-center items-center", "mb-12", "relative")}>
          <hr
            className={clsx(
              "hidden sm:inline",
              "z-0",
              "w-[1061px]",
              "border-charcoal border-dashed",
              "absolute top-[18px]",
            )}
          />
          <h2
            className={clsx(
              "z-10",
              "text-center text-[26px] leading-[32px] font-sharp-grotesk-medium",
              "bg-vood-light",
              "px-[21px] pb-[6px]",
            )}
          >
            What our Voodies are saying
          </h2>
          <img src={VoodiesAreSayingSquiggle.src} />
        </div>

        <div className="video-container rounded-3xl">
          <YouTube videoId={VIDEO_ID} className="max-w-full" />
        </div>

        <ProductReviewPlugin />

        <div className={clsx("flex justify-center items-center", "mt-[25px] sm:mt-[45px]")}>
          <a href="https://www.productreview.com.au/listings/vood" className={clsx("btn btn-sized btn-vood")}>
            See all reviews
          </a>
        </div>
      </div>
    </div>
  );
};

export default VoodiesAreSaying;
