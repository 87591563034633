import React, { FC } from "react";

const ArrowRightIcon: FC = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="7" width="2" height="10" transform="rotate(-90 0 7)" fill="currentColor" />
      <rect
        x="5.00055"
        y="1.3335"
        width="1.88568"
        height="8.48558"
        transform="rotate(-45 5.00055 1.3335)"
        fill="currentColor"
      />
      <rect
        x="10.3335"
        y="5.33301"
        width="1.88569"
        height="7.54274"
        transform="rotate(45 10.3335 5.33301)"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRightIcon;
