import clsx from "clsx";
import Head from "next/head";
import React, { FC, ReactNode } from "react";
import ModalLogin from "src/modules/auth/components/ModalLogin";
import BannerSection from "src/modules/banner/BannerSection";
import ModalCart from "src/modules/cart/components/ModalCart";
import ModalQueue from "src/modules/modalqueue/ModalQueue";

const BasicLayout: FC<{
  title?: string;
  withCart?: boolean; // Show Side Cart
  dark?: boolean;
  className?;
  style?;
  children: ReactNode;
}> = ({ title, withCart = false, dark = false, className, style, children }) => {
  return (
    <div
      className={clsx(
        "relative",
        "min-h-screen",
        "flex flex-col items-center justify-center",
        {
          // "bg-vood-light": !dark,
          "bg-charcoal text-white": dark,
        },
        className,
      )}
      style={style}
    >
      <Head>
        <title>Vood | {title || "Plant-based meals with taste, delivered"}</title>
        <link rel="icon" href="/favicon.png" />
      </Head>

      {children}

      {/* <div>{withCart && <CartFAB />}</div> */}
      {/* <CartMobileCheckoutButton /> */}
      <ModalQueue />
      <ModalCart />
      <ModalLogin />
      <BannerSection />
    </div>
  );
};

export default BasicLayout;
