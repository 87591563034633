import clsx from "clsx";
import React, { FC } from "react";
import Link from "src/layout/Link";

import ImpossiblyDelicious1 from "./ImpossiblyDelicious1.png";
import ImpossiblyDelicious2 from "./ImpossiblyDelicious2.png";
import ImpossiblyDelicious3 from "./ImpossiblyDelicious3.png";

const Block: FC<{ background; children }> = ({ background, children }) => {
  return (
    <div
      className={clsx(
        "p-2 pt-[232px] sm:pt-[192px]",
        "min-h-[300px] sm:min-h-[209px]",
        "text-center text-[16px] leading-[22px]",
        "flex items-start justify-center",
        "bg-no-repeat bg-center",
        "font-sharp-grotesk-book",
        "bg-[backgroundSize:70%]",
      )}
      style={{
        backgroundImage: `url('${background}')`,
        backgroundSize: "70%",
      }}
    >
      {children}
    </div>
  );
};

const ImpossiblyDelicious: FC = () => {
  return (
    <div className="pb-12 flex flex-col items-center justify-center">
      <h2
        className={clsx(
          "mt-12 mb-[40px]",
          "text-center text-[30px] sm:text-[50px] leading-[34px] sm:leading-[56px]",
          "font-sharp-grotesk-medium",
          "relative", // for decorations
          "impossiblydelicious-title",
          // "before:absolute before:top-[-30px] before:left-[-15px] sm:before:left-[-40px]",
          // "after:absolute after:bottom-[-40px] after:right-[10px] sm:after:right-[-30px]",
        )}
      >
        Impossibly delicious.
        <br />
        Surprisingly impactful.
      </h2>
      <div className={clsx("my-8 max-w-[820px]", "flex items-stretch justify-center flex-col sm:flex-row gap-2")}>
        <Block background={ImpossiblyDelicious1.src}>Diverse and dynamic menu of chef-designed meals</Block>
        <Block background={ImpossiblyDelicious2.src}>Delivered fresh using premium local and regional produce</Block>
        <Block background={ImpossiblyDelicious3.src}>Flexible and adaptive pricing rewarding community loyalty</Block>
      </div>
      <div className="text-center">
        <Link href="/start">
          <div className={clsx("btn btn-sized btn-red")}>Get started</div>
        </Link>
      </div>
    </div>
  );
};

export default ImpossiblyDelicious;
