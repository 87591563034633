import clsx from "clsx";
import React, { FC, ReactNode } from "react";

import HowThisWorks1 from "./HowThisWorks1.jpg";
import HowThisWorks2 from "./HowThisWorks2.jpg";
import HowThisWorks3 from "./HowThisWorks3.jpg";
import HowThisWorks4 from "./HowThisWorks4.jpg";
import HowThisWorksSquiggle from "./HowThisWorksSquiggle.png";

const Block: FC<{ image: any; title: ReactNode; children: ReactNode }> = ({ image, title, children }) => {
  return (
    <div className={clsx("flex flex-col items-center justify-center", "text-center", "py-6")}>
      <img src={image.src} className={clsx("w-[220px]", "rounded-full")} />
      <h3 className="text-[22px] sm:text-[26px] leading-[28px] sm:leading-[32px] pt-4 pb-2 font-sharp-grotesk-medium">
        {title}
      </h3>
      <span className="pt-[10px] text-[16px] leading-[22px] font-sharp-grotesk-book">{children}</span>
    </div>
  );
};

const HowThisWorks: FC = () => {
  return (
    <div>
      <div className={clsx("border rounded-3xl", "pt-12 pb-36 px-2", "drop-shadow-xl", "bg-vood-light")}>
        <div className="flex flex-col justify-center items-center mb-12">
          <h2 className="text-center text-[30px] sm:text-[50px] leading-[34px] sm:leading-[56px] text-bold font-sharp-grotesk-medium">
            How this works
          </h2>
          <img src={HowThisWorksSquiggle.src} />
        </div>

        <div className="px-6 flex items-start justify-center flex-col sm:flex-row gap-1">
          <Block
            image={HowThisWorks1}
            title={
              <>
                You choose
                <br className={clsx("hidden sm:inline")} /> your meals
              </>
            }
          >
            Sign up and save with a commitment free weekly order of the meals you want or order individual meals
          </Block>
          <Block
            image={HowThisWorks2}
            title={
              <>
                We source
                <br className={clsx("hidden sm:inline")} /> and create
              </>
            }
          >
            We prepare your meals using the freshest product from our network of local and regional suppliers
          </Block>
          <Block
            image={HowThisWorks3}
            title={
              <>
                We deliver
                <br className={clsx("hidden sm:inline")} /> to your door
              </>
            }
          >
            We deliver your meals to your door in a chilled and insulated box to keep them fresh
          </Block>
          <Block
            image={HowThisWorks4}
            title={
              <>
                You craft your
                <br /> meals your way
              </>
            }
          >
            Prepare your meals following our simple instructions - most meals are ready to eat in minutes
          </Block>
        </div>
      </div>
    </div>
  );
};

export default HowThisWorks;
