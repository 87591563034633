import clsx from "clsx";
import React, { FC } from "react";
import Link from "src/layout/Link";

import ViewOurMenuBackground from "./ViewOurMenuBackground.jpg";

const ViewOurMenu: FC = () => {
  return (
    <div
      className={clsx(
        "flex items-center justify-center",
        // "bg-red-100",
        // "py-12 px-12",
        "relative",
        "h-60",
      )}
    >
      <div
        className={clsx(
          "bg-no-repeat bg-cover bg-center",
          "flex items-center justify-center",
          "absolute",
          "m-auto",
          "-top-24",
          "h-[269px] sm:h-[220px] w-[280px] sm:w-[844px]",
          "max-w-2lg",
          "rounded-3xl",
        )}
        style={{
          backgroundImage: `url('${ViewOurMenuBackground.src}')`,
        }}
      >
        <Link href="/menu">
          <div className={clsx("btn btn-sized btn-white")}>View our menu</div>
        </Link>
      </div>
    </div>
  );
};

export default ViewOurMenu;
