import React, { FC } from "react";

const Spot2: FC<{ className: string }> = ({ className }) => {
  return (
    <svg width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg"  className={className}>
      <g clipPath="url(#clip0_4692_25898)">
        <path
          d="M29.6274 11.0471C19.4714 18.6429 48.2151 14.9392 42.1594 7.17598C40.6034 5.16717 38.3956 4.62312 29.6274 11.0471Z"
          fill="#FCC766"
        />
        <path
          d="M23.5925 26.1383C20.859 27.8542 23.2981 30.5326 25.527 33.6713C30.4473 40.7231 34.6737 38.6724 29.7955 30.9929C27.7769 27.8123 26.1999 24.5061 23.5925 26.1592V26.1383Z"
          fill="#FCC766"
        />
        <path
          d="M2.08134 22.076C0.96691 22.4318 0.0837807 23.2478 0.0207 24.4824C-0.631134 36.6818 28.1757 14.0826 2.08134 22.076Z"
          fill="#FCC766"
        />
        <path
          d="M9.94531 0.192654C5.00399 2.22239 14.2348 16.7863 17.0314 10.0065C17.6622 8.45807 13.7302 -1.41858 9.94531 0.192654Z"
          fill="#FCC766"
        />
      </g>
      <defs>
        <clipPath id="clip0_4692_25898">
          <rect width="43" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Spot2;