import React, { FC } from "react";

const ArrowLeftIcon: FC = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="12.3342" y="5" width="2" height="10" transform="rotate(90 12.3342 5)" fill="currentColor" />
      <rect
        x="7.33362"
        y="10.6665"
        width="1.88568"
        height="8.48558"
        transform="rotate(135 7.33362 10.6665)"
        fill="currentColor"
      />
      <rect
        x="2.00067"
        y="6.66699"
        width="1.88569"
        height="7.54274"
        transform="rotate(-135 2.00067 6.66699)"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
