import clsx from "clsx";
import React, { FC } from "react";
import Container from "src/layout/Container";
import Link from "src/layout/Link";

import ChefDesignedBackground from "./ChefDesignedBackground.png";

const ChefDesigned: FC = () => {
  return (
    <div
      className={clsx("w-full")}
      style={{
        background: "linear-gradient(0, #ffffff 50%, #fff7eb 50%",
      }}
    >
      <Container>
        <div
          className={clsx(
            "my-12 pl-[24px] sm:pl-[80px] py-6 sm:py-[60px]",
            "bg-cover bg-center bg-no-repeat",
            "h-72 sm:h-[446px] w-[1280px] max-w-full",
            "rounded-3xl",
            "flex justify-between items-start flex-col",
          )}
          style={{
            backgroundImage: `url('${ChefDesignedBackground.src}')`,
            backgroundPositionX: "40%",
          }}
        >
          <h2
            className={clsx(
              "text-[22px] sm:text-[46px] leading-[28px] sm:leading-[50px] text-bold",
              "font-sharp-grotesk-medium",
            )}
          >
            Chef-designed,
            <br />
            crafted by you,
            <br />
            ready in minutes
          </h2>

          <Link href="/start">
            <div className={clsx("btn btn-sized btn-red")}>Get started</div>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default ChefDesigned;
