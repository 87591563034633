import clsx from "clsx";
import React, { FC, useState } from "react";
import ArrowLeftIcon from "src/images/ArrowLeftIcon";
import ArrowRightIcon from "src/images/ArrowRightIcon";
import Link from "src/layout/Link";

import MexicanBowlImage from "./MexicanBowlImage.jpg";
import OnTheMenuSquiggle from "./OnTheMenuSquiggle.png";
import ShepardsPieImage from "./ShepardsPieImage.jpg";

interface ListItem {
  title: string;
  subTitle: string;
  text: string;
  image;
  link: string;
}

const listItems: ListItem[] = [
  {
    title: "Mexican Bowl 1.0",
    subTitle: "bolognese ragout, salsa & jalapeño pesto",
    text: "Flavour-filled mushroom ragout with a zesty jalapeño and pine nut coriander pesto, fried bean smash, fragrant mint basmati rice and fresh corn and capsicum salsa, and laced with a citrus pepper dressing.",
    image: MexicanBowlImage,
    link: "mexican-bowl-v1",
  },
  {
    title: "Shepherdless Pie",
    subTitle: "garlic butter roast parsnips",
    text: "Our take on the classic shepherd’s pie with a base of rich mushroom ragù, soft lentils, carrots, and green peas, topped with creamy mashed potato and served with garlic butter roast parsnips. ",
    image: ShepardsPieImage,
    link: "shepherdless-pie",
  },
];

const OnTheMenu: FC = () => {
  const [index, setIndex] = useState(0);

  const handlePrev = () => setIndex((index + listItems.length - 1) % listItems.length);

  const handleNext = () => setIndex((index + 1) % listItems.length);

  const item = listItems[index];

  return (
    <div className={clsx("mt-12 mb-[250px] px-2", "relative", "container m-auto")}>
      <div
        className={clsx(
          "bg-white",
          "drop-shadow-xl",
          "max-w-[955px]",
          "rounded-3xl",
          "flex flex-col justify-start items-center",
          "pt-12 sm:pt-[72px] pb-36 sm:pb-[92px] pr-0 sm:pr-[300px]",
        )}
      >
        <h2 className="text-center text-[26px] leading-[32px] font-sharp-grotesk-medium">On the menu</h2>
        <img src={OnTheMenuSquiggle.src} />
        <h3
          className={clsx(
            "text-[34px] sm:text-[50px] leading-[34px] sm:leading-[56px] text-center",
            "pt-[30px] sm:pt-[89px]",
            "font-sharp-grotesk-medium",
          )}
        >
          {item.title}
        </h3>
        <div
          className={clsx(
            "text-[16px] sm:text-[24px] leading-[22px] sm:leading-[32px] text-center",
            "pt-[13px] sm:pt-[14px]",
            "font-sharp-grotesk-medium",
            "px-2",
          )}
        >
          {item.subTitle}
        </div>

        <div
          className={clsx(
            "flex items-center justify-center",
            "gap-12",
            "w-full",
            "pt-[24px] sm:pt-[42px]",
            "pb-[30px] sm:pb-[39px]",
          )}
        >
          <button
            onClick={handlePrev}
            className={clsx(
              "rounded-full",
              "bg-vood hover:bg-vood-hover",
              "p-6",
              "font-sharp-grotesk-medium",
              "transition-colors duration-200",
            )}
          >
            <ArrowLeftIcon />
          </button>
          <button
            onClick={handleNext}
            className={clsx(
              "rounded-full",
              "bg-vood hover:bg-vood-hover",
              "p-6",
              "font-sharp-grotesk-medium",
              "transition-colors duration-200",
            )}
          >
            <ArrowRightIcon />
          </button>
        </div>

        <div
          className={clsx(
            "hidden sm:inline",
            "font-sharp-grotesk-book text-center text-[16px] leading-[22px]",
            "py-6 sm:pt-[29px] sm:pb-[43px] px-12",
          )}
        >
          {item.text}
        </div>
        <Link href="/menu">
          <div className={clsx("btn btn-sized btn-white", "border border-black")}>View our menu</div>
        </Link>
      </div>

      <Link href={`/eat/${item.link}`}>
        <img
          src={item.image.src}
          className={clsx(
            "w-[280px] sm:w-[630px] h-[269px] sm:h-[788px]",
            "absolute",
            "bottom-[-150px] sm:top-[78px]",
            "left-0 sm:left-auto",
            "right-0 sm:right-0",
            "m-auto sm:m-0",
            "rounded-3xl",
            "object-cover",
            "drop-shadow-xl",
          )}
        />
      </Link>
    </div>
  );
};

export default OnTheMenu;
